<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import ProgresiveLoadAutocompleteOld from "@/components/utils/ProgresiveLoadAutocompleteOld.vue";
import ActividadesRoutes from "@/services/Actividad.services";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";
import ListadoActividadPadre from "@/components/ListadoActividadPadre.vue";

const serv = ActividadesRoutes.getActividad;
export default {
  name: "ActividadProceso",
  components: {
    ListadoActividadPadre,
    ConfirmationDialogComponent,
    ProgresiveLoadAutocompleteOld,
    DataTableComponent
  },
  data: () => ({
    modal_ayuda_rubros: false,
    actividad: null,
    servicio: serv,
    modal_confirmacion: false,
    listado_actividades: [],
    callback: null,
    isLoading: false,
    headers: [
      {
        text: 'Acctividad',
        value: 'nombre',
        align: 'start'
      },
      {
        text: 'Catalogo',
        value: 'catalogo',
        align: 'center'
      },
      {
        text: 'Acciones',
        value: 'actions',
        align: 'center'
      },
    ],
    actions: [
      {
        tooltip: {
          text: "Eliminar actividad",
          color: "red",
        },
        icon: {
          color: "red",
          name: "mdi-trash-can",
        },
        eventName: "registroSeleecionado",
      },
    ],
  }),
  methods: {
    async agregar() {
      if (this.actividad && !this.$route.params.idProceso) {
        if (!this.listado_actividades.find((item) => item.id === this.actividad.id)) {
          this.listado_actividades.push(this.actividad);
          this.actividad = null;
        } else {
          this.temporalAlert({
            show: true,
            message: "La actividad ya se encuentra en la lista",
            type: "warning",
          });
        }

      } else if (this.actividad && this.$route.params.idProceso) {
        await this.services.ActividadesServices.postActividadProceso(
            this.$route.params.idProceso,
            {id_actividad: this.actividad.id});
        this.llenarTabla()
      }
    },
    async registroSeleecionado(item) {
      this.modal_confirmacion = true;
      if (!this.$route.params.idProceso) {
        this.callback = () => this.eleminar(item);
      } else {
        this.callback = () => this.eleminarBd(item);
      }
    },
    async eleminar(item) {
      this.listado_actividades = this.listado_actividades.filter((i) => i.id !== item.id);
    },
    async eleminarBd(item) {
      await this.services.ActividadesServices.deleteActividadProceso(this.$route.params.idProceso, item.id);
      this.llenarTabla()
    },
    async llenarTabla() {
      const {data} = await this.services.ActividadesServices.getActividadProceso(this.$route.params.idProceso)
      this.listado_actividades = data;
    }
  },
  watch: {
    listado_actividades(val) {
      this.$emit("update:actividades", val);
    }
  },
  created() {
    if (this.$route.params.idProceso) {
      this.llenarTabla()
    }
  }
}
</script>

<template>
  <v-card>
    <v-card-title>
      <span>Agregar actividades</span>
    </v-card-title>
    <v-divider/>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="10" class="d-flex gap-1">
          <ProgresiveLoadAutocompleteOld
              v-model="actividad"
              class="flex-grow-1 flex-shrink-1"
              style="min-width: 200px"
              label="Actividad"
              placeholder="Seleccione una institución"
              itemText="nombre"
              itemValue="id"
              hide-details
              clearable
              :return_object="true"
              :service="servicio"
              ref="progresive"
              :id_prov="null"
          />
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  @click="modal_ayuda_rubros = true"
                  v-on="on"
                  v-bind="attrs"
                  size="50"
                  class="mdi-information-variant-circle-outline heartbeat">mdi-information-variant-circle-outline
              </v-icon>
            </template>
            <span>Mostrar rubros disponibles</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" sm="2">
          <v-btn block class="mt-2" color="tableAccent white--text" @click="agregar">Agregar</v-btn>
        </v-col>

        <v-col cols="12">
          <DataTableComponent
              :items="listado_actividades"
              :headers="headers"
              :tiene_paginacion="false"
              :show_loading="isLoading"
              show_actions
              :actions="actions"
              @registroSeleecionado="registroSeleecionado"
          >
            <template v-slot:[`item.catalogo`]={item}>
              <v-chip :color="item?.ae?.color"> {{ item?.ae?.nombre }}</v-chip>
            </template>
          </DataTableComponent>
        </v-col>
      </v-row>
      <ConfirmationDialogComponent
          :show="modal_confirmacion"
          title="¿Deseas eliminar la actividad?"
          message="El cambio es irreversible"
          btnConfirmar="Continuar"
          @close="modal_confirmacion = false"
          :callback="callback"
          @cerrar="$route.params.idProceso ? llenarTabla() : null"
      />
    </v-card-text>
    <ListadoActividadPadre v-model="modal_ayuda_rubros"/>
  </v-card>
</template>

<style scoped>

</style>