<template>
  <section>
    <v-row no-gutters align="center" class="mb-8">
      <v-col cols="12" sm="8" md="4">
        <span class="text-h6 primary--text">
          {{ $route.params.idProceso ? "Actualización" : "Creación" }} de
          proceso de compra
        </span>
        <!--        <p class="my-0">Ejercicio {{ anioFiscal }}</p>-->
      </v-col>
    </v-row>

    <SpinnerComponent :spinning="procesoPacLoading" text="Cargando proceso PAC..."/>
    <v-tabs v-model="tab" background-color="transparent">
      <v-tabs-slider color="blueGrayMinsal"></v-tabs-slider>
      <v-tab v-for="item in tabs" :key="item.value">{{ item.name }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in tabs" :key="item.value">
        <template v-if="item.value === 'solicitudes'">
          <v-row class="justify-center">
            <v-col cols="12" sm="6" md="4">
              <v-card color="bgMinsal" elevation="0" class="mx-2">
                <v-card-title class="justify-center py-0 mt-5 mb-0">
                  <p class="text-h4 mb-3">
                    $
                    {{
                      Intl.NumberFormat("en-US", {
                        currency: "USD",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(total)
                    }}
                  </p>
                </v-card-title>
                <v-card-subtitle class="justify-center d-flex my-0 py-0">
                  <span class="py-0 my-0">Total</span>
                </v-card-subtitle>
                <v-progress-linear color="primary" rounded value="100" class="my-0 py-0"/>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="py-0 my-0" v-if="!proceso_publicado">
            <v-col cols="12" sm="3" md="3">
              <v-btn dark block color="secondary" @click="abrirModal()">Agregar solicitudes
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="7"></v-col>
            <v-col cols="12" sm="3" md="2" align="right">
              <v-btn dark block color="secondary" @click="tab = 1">Siguiente
              </v-btn>
            </v-col>
          </v-row>

          <v-data-table class="mt-4" :headers="encabezado2" :items="listadoObs">
            <template #[`item.monto_asignado`]="{ item }">
              {{
                item.monto_asignado
                    ? Intl.NumberFormat("en-US", {
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(item.monto_asignado)
                    : Intl.NumberFormat("en-US", {
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(0)
              }}
            </template>
            <template #[`item.accion`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="removeSolicitud(item)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar solicitud</span>
              </v-tooltip>
            </template>
            <template #[`item.unidad`]="{ item }">
              {{ item?.unidad }}
              <b>({{ item?.nombre_institucion }})</b>
            </template>
          </v-data-table>
        </template>
        <template v-if="item.value === 'configuracion'">
          <v-row class="mt-4">
            <v-col cols="12" sm="4">
              <v-textarea v-model="form.nombre_proceso" outlined label="Nombre del proceso *"
                          @blur="$v.form.nombre_proceso.$touch()" @input="$v.form.nombre_proceso.$touch()"
                          :error-messages="nombreProcesoError" :disabled="proceso_publicado">
              </v-textarea>
            </v-col>
            <v-col cols="12" sm="4">
              <p class="mb-0">Suma de mercancía:</p>
              <span class="text-h5">
                $
                {{
                  Intl.NumberFormat("en-US", {
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(total)
                }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4">
              <v-autocomplete v-model="form.id_modalidad" :items="modalidades" item-text="nombre" item-value="id"
                              label="Modalidad de compra *" outlined :readonly="$route.params.idProceso ? true : false"
                              @blur="$v.form.id_modalidad.$touch()" @input="$v.form.id_modalidad.$touch()"
                              :disabled="proceso_publicado" :error-messages="modalidadError"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="!proceso_publicado">
            <v-col cols="12" sm="4">
              <v-menu max-width="290px" min-width="auto" offset-y transition="scale-transition" v-model="menuInicio">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field @click:clear="picker = null" :value="fechaFormatted(picker)" append-icon="mdi-calendar"
                                clearable label="Inicio del proceso *" :error-messages="fechaInicioError"
                                @blur="$v.picker.$touch()"
                                outlined placeholder="Seleccione una fecha" readonly v-bind="attrs"
                                v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="picker" type="month" locale="es-Es" header-color="#404a7a"
                               style="margin-right: 40px; margin-top: 15px" :min="moment().format('YYYY-MM-DD')"/>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4">
              <v-menu max-width="290px" min-width="auto" offset-y transition="scale-transition" v-model="menuFin">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field @click:clear="picker2 = null" :value="fechaFormatted(picker2)"
                                append-icon="mdi-calendar" clearable :error-messages="fechaFinError"
                                @blur="$v.picker2.$touch()"
                                label="Finalización del proceso *" outlined placeholder="Seleccione una fecha" readonly
                                v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker :min="picker" :disabled="picker == ''" v-model="picker2" type="month" locale="es-Es"
                               style="margin-top: 15px; color: white" header-color="#404a7a"/>
              </v-menu>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12" sm="4">
              <v-text-field outlined label="Inicio del proceso" :value="fechaFormatted(picker)"
                            :disabled="proceso_publicado"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field outlined label="Finalización del proceso" :value="fechaFormatted(picker2)"
                            :disabled="proceso_publicado"></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="my-6"></v-divider>
          <v-row>
            <v-col md="6" cols="6">
              <v-autocomplete :items="listadoanios" label="Seleccione el año fiscal" v-model="form.anio"
                              item-text="nombre" item-value="id" outlined :error-messages="anioFiscalError"
                              :disabled="proceso_publicado" @blur="$v.form.anio.$touch()">
              </v-autocomplete>
            </v-col>
            <v-col md="6" cols="6">
              <v-text-field v-model="form.monto_planificado" outlined label="Monto planificado *"
                            @blur="$v.form.monto_planificado.$touch()" @input="$v.form.monto_planificado.$touch()"
                            :disabled="proceso_publicado" :error-messages="montoPlanificadoErrors"/>
            </v-col>
          </v-row>
          <v-divider class="my-6"></v-divider>
          <v-row v-if="!proceso_publicado">
            <v-col cols="12" md="4">
              <v-btn block outlined @click="tab = 0">
                Modificar solicitudes
              </v-btn>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn dark block color="#404a7a" elevation="2" :loading="savingProcess" @click="saveProceso">
                {{ $route.params.idProceso ? "actualizar" : "guardar" }} proceso
              </v-btn>
            </v-col>
            <v-col cols="12" md="4" v-if="mostrarCambioModalidad">
              <v-btn dark block color="secondary" @click="tab = 2">Modificar modalidad
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-if="item.value === 'cambio_modalidad'">
          <v-row>
            <v-col cols="12" md="12">
              <cambioModalidad @cambioModalidad="getProcesoPAC" :modalidadActual="Number(form.id_modalidad)"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-btn dark block color="secondary" @click="tab = 1">Volver a configuración
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <actividad-proceso
            v-if="item.value === 'actividad'"
            @update:actividades="actividades"
        />
      </v-tab-item>
    </v-tabs-items>

    <v-row>
      <v-col cols="12" md="4">
        <v-btn block outlined class="mt-12" color="secondary" :to="{ name: 'listado-procesos-pac' }">Volver a listado de
          procesos
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="modalAgregar" max-width="85vw" scrollable>
      <v-card class="py-6 px-6">
        <div class="justify-center d-flex">
          <v-col cols="3"><span class="text-h6 secondary--text">Agregar solicitudes</span></v-col>
          <v-col cols="6" class="text-center">$
            {{
              Intl.NumberFormat("en-US", {
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(total)
            }}
          </v-col>
          <v-col cols="3">
            <v-btn block color="secondary" @click="modalAgregar = false">Finalizar
            </v-btn>
          </v-col>
        </div>

        <v-card-text class="py-4">
          <v-row class="mt-4">
            <v-col cols="6">
              <v-autocomplete label="Unidad solicitante" outlined v-model="form.id_unidad" :items="unidades"
                              item-value="id" :item-text="unidadSolicitanteText" no-data-text="Sin coincidencias"
                              clearable
                              @change="getSolicitud()"></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete :items="obsList" label="OBS" @click:clear="obsselected = null" class="mx-0" clearable
                              item-text="nombre" item-value="id" outlined v-model="obsselected" hide-no-data
                              @change="getSolicitud()"></v-autocomplete>
            </v-col>
          </v-row>

          <data-table-component class="mb-4" :headers="encabezado" no-data-text="No hay datos" :items="solicitudes"
                                v-models:select="perPageModel" v-models:pagina="pageModel"
                                :total_registros="filters.total_rows"
                                @paginar="paginar">
            <template #[`item.check`]="{ item }">
              <v-btn icon v-if="!listadoObsIds.includes(item.id)" @click="addSolicitud(item)">
                <v-icon color="secondary">mdi-plus</v-icon>
              </v-btn>
              <v-btn icon v-else @click="removeSolicitud(item)">
                <v-icon color="error">mdi-close</v-icon>
              </v-btn>
            </template>
            <template #[`item.monto_asignado`]="{ item }">
              {{
                item.monto_asignado
                    ? Intl.NumberFormat("en-US", {
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(item.monto_asignado)
                    : Intl.NumberFormat("en-US", {
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(0)
              }}
            </template>
            <template #[`item.username`]="{ item }">
              {{ item?.Usuario?.Empleado?.Persona?.primer_nombre }}
              {{ item?.Usuario?.Empleado?.Persona?.primer_apellido }}
            </template>
            <template #[`item.unidad`]="{ item }">
              <b>({{ item?.nombre_institucion }})</b>
            </template>
          </data-table-component>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import moment from "moment";
import DataTableComponent from "../../components/DataTableComponent.vue";
import {SpinnerComponent} from "@/components/utils";
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent.vue";
import {decimal, helpers, required} from "vuelidate/lib/validators";
import cambioModalidad from "./cambioModalidadPac.vue";
import ActividadProceso from "@/views/Pac/components/ActividadProceso.vue";

const letter = helpers.regex("letter", /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9 -]*$/); // [A-Za-zñÑáéíóúÁÉÍÓÚ' ]
const priceDecimals = helpers.regex(
    "priceDecimals",
    /^[0-9]+([.][0-9]{1,2})?$/
);
export default {
  name: "creacionProcesoCompra",
  components: {
    ActividadProceso,
    DataTableComponent,
    MoneyTextFieldComponent,
    cambioModalidad,
    SpinnerComponent,
  },
  validations: {
    form: {
      nombre_proceso: {required, letter},
      id_modalidad: {required},
      anio: {required},
      monto_planificado: {required, decimal, priceDecimals},
    },
    picker: {required},
    picker2: {required},
  },
  data() {
    return {
      listado_actividades: [],
      modalAgregar: false,
      encabezado: [
        {
          align: "center",
          value: "check",
        },
        {
          text: "Correlativo",
          value: "correlativo",
          align: "start",
          width: "10%",
        },
        {
          text: "OBS",
          value: "obs",
          align: "start",
          width: "10%",
        },
        {
          text: "Usuario solicitante",
          value: "username",
          align: "start",
        },
        {
          text: "Información adicional",
          value: "informacion_adicional",
          align: "start",
          width: "20%",
        },
        {
          text: "Unidad",
          value: "unidad",
          align: "start",
        },
        {
          text: "Monto ($)",
          value: "monto_asignado",
          align: "right",
        },
        {
          text: "Estado",
          value: "estado_solicitud",
          align: "start",
          width: "8%",
        },
      ],
      listadoanios: [],
      obs_timer: null,
      obsselected: null,
      solicitudes: [],
      unidades: [],
      obs: [],
      check: false,
      pagination: false,
      obsList: [],
      selected: [],
      listadoObs: [],
      listadoObsIds: [],
      tab: null,
      proceso: null,
      modalidades: [],
      picker: moment().format("YYYY-MM"),
      picker2: null,
      form: {
        nombre_proceso: null,
        id_modalidad: null,
        anio: null,
        monto_planificado: null,
      },
      savingProcess: false,
      menuInicio: false,
      menuFin: false,
      procesoPacLoading: false,
    };
  },
  computed: {
    proceso_publicado() {
      if (!this.proceso) return false;
      const [proceso_compra] = this.proceso?.ProcesoCompras;
      return !!proceso_compra?.fecha_publicacion
    },
    encabezado2() {
      const headers_iniciales = [
        {
          text: "Correlativo",
          value: "correlativo",
          align: "start",
        },
        {
          text: "OBS",
          value: "obs",
          align: "start",
        },
        {
          text: "Unidad",
          value: "unidad",
          align: "start",
        },
        {
          text: "Solicitante",
          value: "solicitante",
          align: "start",
        },
        {
          text: "Información adicional",
          value: "informacion_adicional",
          align: "start",
        },
        {
          text: "Monto ($)",
          value: "monto_asignado",
          align: "center",
        },
        {
          text: "Estado",
          value: "estado_solicitud",
          align: "start",
        },
      ]
      if (!this.proceso_publicado) {
        headers_iniciales.push({
          text: "Acciones",
          value: "accion",
          align: "center",
        },)
      }
      return headers_iniciales;
    },
    unidadSolicitanteText() {
      return unidades => `${unidades.nombre} - ${unidades.institucion}`;
    },
    anioFiscalError() {
      const errors = [];
      if (!this.$v.form.anio.$dirty) return errors;
      !this.$v.form.anio.required && errors.push("El año fiscal es requerido");
      return errors;
    },
    montoPlanificadoErrors() {
      const errors = [];
      if (!this.$v.form.monto_planificado.$dirty) return errors;
      !this.$v.form.monto_planificado.required &&
      errors.push("El monto planificado es requerido");
      // !this.$v.form.monto_planificado.numeric &&
      // errors.push("El monto planificado debe ser numérico");
      !this.$v.form.monto_planificado.priceDecimals &&
      errors.push("El monto planificado debe tener máximo 2 decimales");
      return errors;
    },
    idSeguimientoProceso() {
      return this.proceso?.ProcesoCompras[0]?.id_seguimiento_proceso;
    },
    mostrarCambioModalidad() {
      return !!this.$route.params.idProceso;
    },
    mostrarTabsConfiguraciones() {
      if (!this.idSeguimientoProceso) return false;

      return [1, 11].includes(+this.idSeguimientoProceso);
    },
    tabs() {
      if (!this.$route.params.idProceso) {
        return [
          {name: "Solicitudes", value: "solicitudes"},
          {name: "Actividad", value: "actividad"},
          {name: "Configuración", value: "configuracion"},
        ];
      }

      if (!this.idSeguimientoProceso) return [];

      const result = [];
      // if (this.mostrarTabsConfiguraciones) {
      result.push({name: "Solicitudes", value: "solicitudes"});
      result.push({name: "Actividad", value: "actividad"})
      result.push({name: "Configuración", value: "configuracion"});
      // }

      if (this.mostrarCambioModalidad) {
        result.push({name: "Cambio de modalidad", value: "cambio_modalidad"});
      }

      return result;
    },
    ...mapState(["anioFiscal"]),
    ...mapState("pac", ["filters"]),
    ...mapState(["selectedUnidad", "anioFiscal"]),
    pageModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setPage(value);
      },
    },
    perPageModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.setPerPage(value);
      },
    },
    total() {
      let suma = 0;
      this.listadoObs.forEach((proceso) => {
        suma += Number(proceso.monto_asignado);
      });
      return suma;
    },
    nombreProcesoError() {
      const errors = [];
      if (!this.$v.form.nombre_proceso.$dirty) return errors;
      !this.$v.form.nombre_proceso.required &&
      errors.push("El nombre del proceso es requerido");
      !this.$v.form.nombre_proceso.letter &&
      errors.push(
          "El nombre del proceso no puede contener caracteres especiales"
      );
      return errors;
    },
    modalidadError() {
      const errors = [];
      if (!this.$v.form.id_modalidad.$dirty) return errors;
      !this.$v.form.id_modalidad.required &&
      errors.push("La modalidad de compra es requerida");
      return errors;
    },
    fechaInicioError() {
      const errors = [];
      if (!this.$v.picker.$dirty) return errors;
      !this.$v.picker.required && errors.push("Debe de seleccionar una fecha");
      return errors;
    },
    fechaFinError() {
      const errors = [];
      if (!this.$v.picker2.$dirty) return errors;
      !this.$v.picker2.required && errors.push("Debe de seleccionar una fecha");
      return errors;
    },
  },
  methods: {
    actividades(actividades) {
      const extraer_ids = _.cloneDeep(actividades.map((act) => {
        return act.id
      }));
      this.listado_actividades = extraer_ids;
    },
    ...mapMutations("pac", ["setFiltros", "setFiltros", "setPage"]),
    async getSolicitud() {
      if (this.form.id_unidad || this.obsselected) {
        this.setPage(1);
        const response = await this.services.PacProcesos.getSolicitudObs({
          ...this.filters,
          id_obs: this.obsselected,
          id_unidad: this.form.id_unidad,
          proceso: true,
        });
        this.solicitudes = response?.data;
        this.filters.page = Number(response.headers.page);
        this.filters.per_page = Number(response.headers.per_page);
        this.filters.total_rows = Number(response.headers.total_rows);
      } else {
        const response2 = await this.services.PacProcesos.getSolicitudObs({
          ...this.filters,
          proceso: true,
        });
        this.solicitudes = response2?.data;
        this.filters.page = Number(response2.headers.page);
        this.filters.per_page = Number(response2.headers.per_page);
        this.filters.total_rows = Number(response2.headers.total_rows);
      }
    },
    fechaFormatted(fecha) {
      if (fecha !== null) {
        return moment(fecha).format("MMMM YYYY");
      } else {
        return "";
      }
    },
    abrirModal() {
      this.modalAgregar = true;
    },
    paginar(filtros) {
      const {cantidad_por_pagina, pagina} = filtros;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      this.getSolicitud();
    },
    async getUnidades() {
      const response = await this.services.UnidadesServices.getAllUnidades();
      this.unidades = response.data;
    },
    async addSolicitud(item) {
      if (this.$route.params.idProceso) {
        const {status} =
            await this.services.PacServices.postSolicitudProcesoPAC(
                this.$route.params.idProceso,
                {id_solicitud_necesidad: item.id}
            );
        if (status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Solicitud agregada con éxito",
          });
          this.getProcesoPAC();
        }
      } else {
        this.listadoObs.push(item);
        this.listadoObsIds.push(item.id);
      }
    },
    async fetchModalidades() {
      const params = {
        compra_tradicional: true,
      };
      if (!this.$route.params.idProceso) params.solo_migracion = false;
      const response = await this.services.Paac.getModalidades(params);
      if (response) {
        this.modalidades = response.data.forma_contratacion;
      }
    },
    async saveProceso() {
      try {
        this.savingProcess = true;
        if (this.$v.$invalid) {
          this.$v.$touch();
          this.temporalAlert({
            show: true,
            type: "error",
            message: "Por favor, revise los campos marcados en rojo.",
          });
          this.savingProcess = false;
          return;
        }
        if (this.picker === null || this.picker2 === null) {
          this.temporalAlert({
            show: true,
            type: "error",
            message: "Debe establecer el inicio y fin del proceso",
          });
          this.savingProcess = false;
          return;
        }
        if (this.picker > this.picker2) {
          this.temporalAlert({
            show: true,
            type: "error",
            message: "La fecha de inicio no puede ser mayor a la fecha final.",
          });
          this.savingProcess = false;
          return;
        }
        // if (this.listadoObsIds.length <= 0) {
        //   this.temporalAlert({
        //     show: true,
        //     type: "warning",
        //     message:
        //         "Debe seleccionar por lo menos una solicitud para el proceso",
        //   });
        //   this.savingProcess = false;
        //   this.tab = 0;
        //   return;
        // }

        let params = {
          solicitudes_asignadas: this.listadoObsIds,
          id_modalidad_compra: this.form.id_modalidad,
          suma_mercancia: this.total.toFixed(2),
          nombre_proceso: this.form.nombre_proceso,
          fecha_inicio: this.moment(this.picker)
              .startOf("month")
              .format("DD-MM-YYYY"),
          fecha_fin: this.moment(this.picker2)
              .endOf("month")
              .format("DD-MM-YYYY"),
          anio_fiscal: this.form.anio,
          monto_planificado: this.form.monto_planificado,
          listado_actividades: this.listado_actividades,
        };

        let response = null;

        let updateBody = {
          nombre_proceso: this.form.nombre_proceso,
          id_modalidad_compra: this.form.id_modalidad,
          fecha_inicio: this.moment(this.picker)
              .startOf("month")
              .format("DD-MM-YYYY"),
          fecha_fin: this.moment(this.picker2)
              .endOf("month")
              .format("DD-MM-YYYY"),
          anio_fiscal: this.form.anio,
          monto_planificado: this.form.monto_planificado,
          listado_actividades: this.listado_actividades,
        };

        if (this.$route.params.idProceso) {
          const {
            data: {
              procesoValidoPublicado
            }
          } = await this.services.Paac.validarProcesoPublicado(this.$route.params.idProceso);
          if(!procesoValidoPublicado) {
            if(this.listado_actividades.length < 1 && ![27, 25, 18, 24].includes(this.form.id_modalidad)) {
              this.temporalAlert({
                show: true,
                type: "warning",
                message: "Debe seleccionar al menos una actividad",
              });
              return false
            }
          }
          response = await this.services.PacServices.updateProcesoPAC(
              this.$route.params.idProceso,
              updateBody
          );
        } else {
          if(this.listado_actividades.length < 1  && ![27, 25, 18, 24].includes(this.form.id_modalidad)) {
            this.temporalAlert({
              show: true,
              type: "warning",
              message: "Debe seleccionar al menos una actividad",
            });
            return false
          }
          response = await this.services.PacProcesos.saveProcesos(params);
        }

        if (response.status === 201 || response.status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: `Proceso ${this.$route.params.idProceso ? "actualizado" : "creado"
            } exitosamente.`,
          });
          await this.$router.push({name: "listado-procesos-pac"});
        }
      } catch (error) {
      } finally {
        this.savingProcess = false;
      }
    },
    async obtenerAniosFiscales() {
      const query = this.$route.params.idProceso ? {editar_pac: true} : {};
      const {data} = await this.services.PacServices.listadoAniosFiscales(
          query
      );
      this.listadoanios = data;
    },
    async removeSolicitud(item) {
      if (this.$route.params.idProceso) {
        const {status} =
            await this.services.PacServices.deleteSolicitudProcesoPAC(
                this.$route.params.idProceso,
                item.id
            );

        if (status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Solicitud eliminada con éxito",
          });
          this.getProcesoPAC();
        }
      } else {
        this.listadoObs = this.listadoObs.filter(
            (solicitud) => solicitud.id !== item.id
        );
        this.listadoObsIds = this.listadoObsIds.filter(
            (solicitud) => solicitud !== item.id
        );
      }
    },
    async getOBSSegmentos() {
      const {status, data} = await this.services.PacServices.getOBSSegmentos({
        pagination: false,
      });

      if (status === 200) {
        this.obsList = data.map((col) => ({
          id: col.id,
          nombre: `${col.codigo} ${col.nombre}`,
        }));
      }
    },
    async getProcesoPAC() {
      this.procesoPacLoading = true;
      try {
        const {status, data} = await this.services.PacServices.getProcesoPAC(
            this.$route.params.idProceso
        );

        if (status === 200) {
          this.proceso = data.proceso;
          this.listadoObsIds = data.solicitudes.map((col) => col.id);
          this.listadoObs = data.solicitudes;

          this.form.anio = data.proceso.id_anio_fiscal_pac ?? this.anioFiscal;

          this.form.nombre_proceso = data.proceso?.nombre;
          this.form.id_modalidad = data.proceso?.id_modalidad_compra;
          this.picker = moment(data.proceso?.fecha_inicio).format("YYYY-MM");
          this.picker2 = moment(data.proceso?.fecha_fin).format("YYYY-MM");
          this.form.monto_planificado = data.proceso?.monto_planificado;
        }
      } catch (error) {
        this.pushAppMessage({
          type: "error",
          message: "Error al cargar el proceso.",
        });
      } finally {
        this.procesoPacLoading = false;
      }
    },
  },
  watch: {
    picker() {
      if (!this.$route.params.idProceso) this.picker2 = this.picker;
    },
  },
  async created() {
    if (this.$route.params.idProceso) {
      await this.getProcesoPAC();
    }

    this.setFiltros({
      page: 1,
      per_page: 10,
      total_rows: null,
      paginacion: "true",
      id_estado: "2,6,7",
    });
    await this.getOBSSegmentos();
    await this.getSolicitud();
    await this.getUnidades();
    await this.fetchModalidades();
    await this.obtenerAniosFiscales();
  },
};
</script>
